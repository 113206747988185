$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; span.chcklst-stroked {
  text-decoration: line-through;
}

@mixin checklist-svg-icon($svg) {
  &::before {
    background-color: var(--primary);
    content: "";
    mask: svg-uri(
        '<svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="14px" height="16px" viewBox="0 0 448 512"><path d="#{$svg}"></path></svg>'
      )
      no-repeat 50% 50%;
    mask-size: cover;
    width: 1em;
    height: 1em;
  }
}

span.chcklst-box {
  cursor: pointer;
  display: inline-flex;
  vertical-align: text-bottom;

  &:not(.checked) {
    &.fa-square-o {
      @include checklist-svg-icon(
        "M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
      );
    }

    &.fa-square {
      @include checklist-svg-icon(
        "M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"
      );
    }
  }

  &.checked {
    @include checklist-svg-icon(
      "M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"
    );

    &.fa-square-check-o {
      @include checklist-svg-icon(
        "M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
      );
    }
  }

  &.readonly {
    pointer-events: none;
  }

  &.hidden {
    display: none;
  }
}

ul li.has-checkbox {
  list-style-type: none;

  .list-item-checkbox {
    margin-left: -1.33em;
  }
}

.fa-spin {
  display: inline-flex;
  vertical-align: text-bottom;
  animation: fa-spin 2s infinite linear;
  width: 1em;
  height: 1em;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}
